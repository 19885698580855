const experience = [
  {
    company: "Chainalysis",
    title: "Software Engineer",
    duration: {
      start: "Januaray 2022",
      end: "Present",
    },
    bullets: ["Building trust in blockchain"],
  },
  {
    company: "FlightAware",
    title: "Associate Software Engineer",
    duration: {
      start: "August 2021",
      end: "January 2022",
    },
    bullets: [
      "Implemented feature improvements and bug fixes",
      "Coordinated with UI/UX design for implementation of updated FlightPage UI",
      "Engineered several web in docker solutions to core web product",
    ],
  },
  {
    company: "Fast + Epp",
    title: "Structural Engineer",
    duration: {
      start: "September 2019",
      end: "October 2020",
    },
    bullets: [
      "Developed specialized calculation sheets written in Python on Jupyter Notebook, allowing for maintainable parametric calculation sheets more customizable than existing Excel spreadsheets",
      "Utilized Finite Element Analysis models to implement multi-modal analysis of a pedestrian bridge structure resulting in a steel box girder bridge  design that was 30% below estimated steel tonnage and considerate of footfall vibrations",
      "Completed schematic level design of post-tensioned concrete roadway on $250M project providing fully scoped design documents for pricing",
    ],
  },
  {
    company: "Various",
    title: "Structural Engineer & Project Manager",
    duration: {
      start: "August 2008",
      end: "September 2019",
    },
    bullets: [
      "Orchestrated design of mass timber connections on $2.5M insitutional project",
      "Coordinated geotechnical investigations and oversaw deep foundation construction on $50M commercial project",
      "Utilizing Excel VBA and Python, updated pipe support database of over 5000 entries to include stress isometric drawings",
      "Coordinated with Senior Engineers to qualify five novel steel pipe supports",
      "Collaborated with Senior Engineers to develop a steel restraint system to prevent the total failure of a cracked piping flange",
      "Project Manager for residential development projects of up to $10M managing multiple aspects of construction including site work, carpentry and skilled trades",
    ],
  },
];

export default experience;
